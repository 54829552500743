.open-affix-warning {
    padding-left: 53px;
    padding-bottom: 10px;
    color: #f3a575;
    max-width: 550px;

    & .bases {
        padding-left: 5px;
    }
    & .bold {
        color: #f69158;
        font-weight: bold;
    }
}