.expedition-row {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.expedition-selected-basetype {
    background-color: #2b3d4f !important;
    border-bottom: 1px solid #000;
}
.expedition-row-container {
    margin: 20px;
}
.expedition-action {
    padding: 20px;
    min-width: 100px;
}
.expedition-basetype-cell {
    padding: 20px 5px 20px 20px;
    min-width: 200px;
}
.expedition-options-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.expedition-option-text {
    padding-left: 2px;
    font-size: 18px;
}
.expedition-img-container {
    cursor: pointer;
    position: relative;
    float: left;
    border: 1px solid #1b1e22;
    margin: 5px;
    min-width: 60px;
}
.expedition-img {
    max-height: 84px;
    max-width: 84px;
}
.expedition-img-value {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffebb7;
    font-size: 14px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: #1b1e21;
}

.expedition-fade {
    opacity: 0.25;
}

.expedition-row:nth-child(odd) {
    background-color: #2d3038;
}
.expedition-row:nth-child(even) {
    background-color: #22262c;
}
.item-tooltip {
    position: relative;
}
.item-tooltip-text {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.84);
    color: #ae521a;
    font-weight: bold;
    border-radius: 6px;
    margin: 5px;
    left: -10px;
    top: 0;
    white-space:nowrap;
}
.item-tooltip-text-wiki {
    font-size: 14px;
    color: #d5d5d5;
}
.item-tooltip:hover .item-tooltip-text {
    visibility: visible;
}
a.item-link {
    color: #ae521a;
}
.expedition-selected-item {
}
.expedition-item-regex-area {
    margin: 10px;
    min-height: 200px;
}
.expedition-col-20 {
    width: 20%;
}
.expedition-col-40 {
    width: 40%;
}
.expedition-col-60 {
    width: 60%;
}
.expedition-selection-header {
    padding: 10px 20px 20px;
}
.expedition-selection-area {
    padding: 20px;
}
.select-league-info {
    font-size: 16px;
    padding-left: 20px;
}
.select-league {
    min-width: 220px;
    background-color: #444e5b;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #000000;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    letter-spacing: 1px;
}
.option-league {
    font-size: 16px;
}
.auto-add-items {
    padding-top: 10px;
}
.low-value-uniques {
    padding-top: 10px;
}
.economy-info {
    padding-left: 20px;
    margin: 0;
    font-size: 14px;
    color: #8F8F9D;
}

.expedition-help-heading {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 14px;
}
.expedition-fallback-price {
    padding-left: 1px;
    color: #ecba53;
    font-weight: bold;
}
.expedition-load-more {
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    color: #dedede;
    border: 1px solid #050101;
    transition-duration: 0.3s;
    background-color: #454f5b;
    padding: 10px;
    margin: 10px;

}
.expedition-load-more:hover {
    background-color: #55616e;
}
.expedition-load-wrap {
    padding-left: 20px;
}
.span-help-number {
    color: #ecba53;
}
.unseen-unique {
    color: #bd7f40;
}
.chaos-orb-price-icon {
    padding-top: 3px;
    height: 16px;
    width: 16px;
    float: right;
}