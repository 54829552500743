.page-link-header {
    white-space: nowrap;
    flex-grow: 10;
    padding: 6px 20px 8px 8px;
    font-size: 24px;
    color: #ffffff;
}

.support-link {
    margin-top: 0;
    margin-bottom: 2px;
    padding-left: 5px;
}

.poe2-link {
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 5px;
    padding-right: 5px;
    padding-left: 10px;
}

.support-icon {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 4px;
    height: 20px;
}

.page-link {
    flex-grow: 0;
    padding-left: 7px;
    padding-bottom: 8px;
    padding-top: 2px;
    font-size: 18px;
    white-space: nowrap;
}

.page-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.page-link-icon {
    position: relative;
    top: 11px;
    margin-top: -7px;
    padding-right: 5px;
    height: 32px;
    width: 32px;
}

a {
    color: var(--color-text);
    text-decoration: none;
}

a:hover {
    color: var(--color-text-highlight);
    text-decoration: underline;
}

.page-link-current {
    background-color: var(--color-area);
}

.page-link-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}

.source-info {
    padding-top: 20px;
}

@media (max-width: calc(230px + 1250px + 20px)) {
    .page-links {
        flex-direction: row;
    }

    .page-link {
        padding-right: 20px;
    }

    .page-link-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.about-info {
    padding: 100px 8px 20px;
    font-size: 16px;
    align-content: flex-end;
    flex-grow: 1;
    height: 100%;
}

.source-link {
    color: var(--color-text-link);
}
