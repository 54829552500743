.collapsable-wrapper {
    display: flex;
    width: 100%;
    margin: 5px 20px;
    flex-direction: column;
}
.collapsable-header {
    font-size: 16px;
    border-radius: 3px 3px 0 0;
    padding: 18px;
    display: flex;
    flex-direction: row;
    background-color: #454f5b;
    gap: 10px;
    cursor: pointer;
}
.collapsable-icon {
    font-size: 18px;
    font-weight: bold;
    font-family: monospace;
    color: #9d9d9d;
}
.collapsable-header:hover {
    background-color: #55616e;
}
.collapsable-content {
    background-color: #121215;
    padding: 30px;
}
.collapsable-anim {
}