:root {
    --link-width: 230px;
    --main-content-width: 1250px;
}

body {
    font-family: "Manrope", serif;
    color: var(--color-text);
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--color-site-bg);
}

#root {
    height: 100%;
}

.content-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.content-links {
    display: flex;
    flex-direction: column;
    width: var(--link-width);
    align-items: stretch;
    min-height: 500px;
    background: var(--color-bg);
}

.content-link-icon {
    width: 118px;
    height: 209px;
    top: 274px;
    left: calc(var(--link-width) - 118px);
    z-index: -1;
    position: absolute;
}

.content-height-wrapper {
    display: flex;
    height: 100%;
    flex-flow: column;
}

.content-main {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    color: #d5d5d5;
}

.content-main-area {
    width: var(--main-content-width);
    max-width: var(--main-content-width);
    background: var(--color-bg);
}

.content-left-gfx {
    flex-grow: 1;
    width: 0;
}

.content-right-gfx {
    flex-grow: 1;
    width: 0;
}

.page-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

@media (max-width: calc(230px + 1250px + 20px)) {
    .content-links {
        width: 100%;
        background-image: none;
        display: flex;
        flex-direction: row;
        flex-shrink: initial;
        min-height: inherit;
    }

    .content-height-wrapper {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
    }

    .content-container {
        width: 100%;
        flex-direction: row;
        align-content: flex-start;
        justify-content: flex-start;
        flex-flow: column;
    }

    .content-link-icon {
        display: none;
    }

    .content-main-area {
        min-width: 150px;
        width: 100%;
        border: 0 solid #464646;
    }
}

.row {
    width: 100%;
    display: flex;
}

.full-size {
    width: 100%;
}

.half-size {
    width: 50%;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.spacer-top {
    padding-top: 14px;
}

.col-align-right {
    margin-left: auto;
}

.col-3 {
    min-width: 200px;
    width: 32%;
}

.eq-col-2 {
    flex-grow: 1;
    width: 49%;
}

.eq-col-3 {
    flex-grow: 1;
    width: 32%;
}

@media (max-width: 860px) {
    .eq-col-3 {
        width: 49%;
    }
}

@media (max-width: 425px) {
    .eq-col-3 {
        width: 99%;
    }
}
